import PropTypes from "prop-types";
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useHasPermission } from "../../hooks/useHasPermission";
import { TheLayout } from "../containers";
import { SuspenceFallbackContainer } from "./SuspenceFallbackContainer";

export const PrivateRoute = ({
    component: Component,
    layout = true,
    permissions = null,
}) => {
    const { hasPermission, isLogged } = useHasPermission();

    const isAccesible = permissions === null || hasPermission(permissions);

    if (!isLogged) return <Navigate to="/" />;
    if (!isAccesible) return <Navigate to="/app/unauthorized" />; // Nueva ruta para acceso denegado

    return layout ? (
        <TheLayout>
            <Suspense fallback={<SuspenceFallbackContainer />}>
                <Component />
            </Suspense>
        </TheLayout>
    ) : (
        <Suspense fallback={<SuspenceFallbackContainer />}>
            <Component />
        </Suspense>
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType.isRequired, // Más específico que object
    layout: PropTypes.bool,
    permissions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};
