import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    openForm: false,
    partnetshipList: [],
    reports: [],
    activeReport: null,
};

export const serviceSocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.socialServiceShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.partnertshipsShow:
            return {
                ...state,
                partnetshipList: action.payload.list,
            };
        case types.setSocialServiceActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.startUnsetActiveServiceSocial:
            return {
                ...state,
                active: null,
            };
        case types.socialServiceSetReports:
            return {
                ...state,
                reports: action.payload,
            };
        case types.socialServiceUnsetReports:
            return {
                ...state,
                reports: [],
            };
        case types.socialServiceSetActiveReport:
            return {
                ...state,
                activeReport: action.payload,
            };
        case types.socialServiceUnsetActiveReport:
            return {
                ...state,
                activeReport: null,
            };
        default:
            return state;
    }
};
