import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    mergeFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    active: null,
    list: [],
};

export const RequestRegistration = createSlice({
    name: "RequestRegistration",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },

        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.list = initialState.list;
        },

        setActive: (state, action) => {
            state.active = action.payload;
        },
        clearActive: (state) => {
            state.active = initialState.active;
        },

        clearState: (state) => {
            state = initialState;
        },
    },
});

export const {
    setPagination,
    clearPagination,

    setList,
    clearList,

    setActive,
    unsetActive,

    clearState,
} = RequestRegistration.actions;

export default RequestRegistration.reducer;

export const startSaveRequestRegistration = (
    values,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");
        const formData = new FormData();

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        const resp = await CallWithFormData(
            `operators/request-registration/create`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro de solicitud creado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startShowRequestRegistration = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const { page, number_rows, query, status, from, to } = values;
        const params = new URLSearchParams();
        params.set("page", page);
        params.set("per_page", number_rows);
        params.set("q", query);
        if (status) {
            params.set("status", status);
        }
        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }

        const resp = await Call(
            `operators/request-registration`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateRequestRegistration = (
    values,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        let data = objectToFormData(values);
        data.delete("files");
        data.delete("removed_files");
        const formData = new FormData();

        if (values?.files) {
            values.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file);
            });
        }

        if (values?.removed_files?.length > 0) {
            formData.append(
                "removed_files",
                values.removed_files.map((rf) => rf.uid).join(",")
            );
        }

        const resp = await CallWithFormData(
            `operators/request-registration/update/${values.id}`,
            "POST",
            mergeFormData(data, formData)
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro de solicitud actualizado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteRequestRegistration = (id, callback) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());
            const resp = await Call(
                `operators/request-registration/delete/${id}`,
                "DELETE"
            );
            if (resp.success) {
                callback();
                Swal.fire({
                    title: "Éxito",
                    text: "Registro de solicitud eliminado.",
                    icon: "success",
                });
            } else {
                errorHandler(resp);
            }
            dispatch(stopUILoading());
        });
    };
};
