import Swal from "sweetalert2";
import { errorHandler } from "../../../../helpers/errorHandler";
import { Call, CallWithFormData } from "../../../../helpers/fetch";
import { startUILoading, stopUILoading } from "../ui/ui";
import { checkingFinish, login, logout } from "./auth";
import { startSetCyclesByUser } from "../../teachers/v2/cycles";

export const startLogin = (student_number, password, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            "students/auth/login",
            "POST",
            { student_number, password },
            false
        );
        if (resp.success) {
            const { user, session, cycles } = resp.success;
            localStorage.setItem("session", session);
            dispatch(login(user, session));
            dispatch(startSetCyclesByUser(cycles));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startRenew = () => {
    return async (dispatch) => {
        if (!localStorage.getItem("session")) {
            return dispatch(checkingFinish());
        }
        dispatch(startUILoading());
        const resp = await Call("students/auth/renew");
        if (resp.success) {
            const { user, session, cycles } = resp.success;
            localStorage.setItem("session", session);
            dispatch(login(user, session));
            dispatch(startSetCyclesByUser(cycles));
        } else {
            localStorage.removeItem("session");
            dispatch(checkingFinish());
        }
        dispatch(stopUILoading());
    };
};

export const startLoginByID = (user) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const formData = new FormData();
        formData.append("student_id", user.id);
        const resp = await CallWithFormData(
            "students/auth/loginByID",
            "POST",
            formData
        );
        if (resp.success) {
            const { user, session, cycles } = resp.success;
            localStorage.setItem("session", session);
            dispatch(login(user, session));
            dispatch(startSetCyclesByUser(cycles));
        }
        dispatch(stopUILoading());
    };
};

export const startForgotMyPassword = (student_number, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            "students/auth/password/email",
            "POST",
            { student_number },
            false
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success.msg,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startRecoveryPassword = (password, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");
        const email = urlParams.get("email");

        const resp = await Call(
            "students/auth/password/reset",
            "POST",
            { password, token, email },
            false
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success.msg,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};
