import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    list: [],
    list_two: [],
    listByStudent: [],
    listByStudentInExtraordinary: [],
    active: JSON.parse(localStorage.getItem("schoolSubject")) || null,
    openForm: false,
};

export const schoolsubjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.schoolSubjectShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.schoolSubjectList:
            return {
                ...state,
                list: action.payload.list,
            };
        case types.schoolSubjectListClear:
            return {
                ...state,
                list: initialState.list,
            };
        case types.schoolSubjectListTwo:
            return {
                ...state,
                list_two: action.payload.list,
            };
        case types.schoolSubjectListByStudent:
            return {
                ...state,
                listByStudent: action.payload,
            };
        case types.schoolSubjectListByStudentInExtraordinary:
            return {
                ...state,
                listByStudentInExtraordinary: action.payload,
            };
        case types.schoolSubjectClearListByStudentInExtraordinary:
            return {
                ...state,
                listByStudentInExtraordinary:
                    initialState.listByStudentInExtraordinary,
            };
        case types.schoolSubjectOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.schoolSubjectCloseForm:
            return {
                ...state,
                openForm: false,
            };
        case types.schoolSubjectSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.schoolSubjectUnsetActive:
            return {
                ...state,
                active: null,
            };

        default:
            return state;
    }
};
