import React, { useEffect, useState } from "react";
import { IconApp } from "./IconApp";

export const StudentStatusIcon = ({
    status,
    iconClassName = "",
    iconSizeClassName = "",
}) => {
    const [iconColor, setIconColor] = useState("");

    useEffect(() => {
        switch (status) {
            case "NORMAL":
            case "EQUIVALENTE":
            case "PENDIENTE DE INGRESO":
                setIconColor("#58D68D");
                break;
            case "SERVICIO SOCIAL":
            case "EGRESADO":
            case "GRADUADO":
            case "TITULADO":
                setIconColor("#3FA6EA");
                break;
            case "SOLICITANTE":
                setIconColor("#FFC300");
                break;
            case "DUPLICADO":
                setIconColor("#FF5733");
                break;
            case "SUSPENDIDO":
            case "BAJA TEMPORAL":
            case "BAJA ACADEMICA":
            case "BAJA ADEUDO BIBLIOTECA":
            case "BAJA POR DOCUMENTOS":
            case "BAJA POR ADEUDO":
            case "BAJA INFORMAL":
            case "BAJA DEFINITIVA":
                setIconColor("#C70039");
                break;
            default:
                setIconColor("");
                break;
        }
    }, [status]);

    return (
        <IconApp
            iconClassName={`fa-solid ${
                iconClassName ? iconClassName : "fa-circle-info"
            } ${iconSizeClassName ? iconClassName : "fa-xl"} mt-1`}
            color={iconColor}
        />
    );
};
