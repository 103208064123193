import Swal from "sweetalert2";
import { errorHandler } from "../../../../helpers/errorHandler";
import { Call, CallWithFormData } from "../../../../helpers/fetch";
import { types } from "../../../types";
import { startUILoading, stopUILoading } from "../ui/ui";
import { checkingFinish, login, logout } from "./auth";

export const startLogin = (email, password, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        email = email.toLowerCase();
        const resp = await Call(
            "teachers/auth/login",
            "POST",
            { email, password },
            false
        );
        if (resp.success) {
            const { user, session } = resp.success;

            localStorage.setItem("session", session);
            dispatch(login(user, session));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startLoginByID = (user) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const formData = new FormData();
        formData.append("user_id", user.id);
        const resp = await CallWithFormData(
            "teachers/auth/loginByUserID",
            "POST",
            formData
        );
        if (resp.success) {
            const { user, session } = resp.success;
            localStorage.setItem("session", session);
            dispatch(login(user, session));
        }
        dispatch(stopUILoading());
    };
};

export const startForgotMyPassword = (email, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        email = email.toLowerCase().trim();
        const resp = await Call(
            "teachers/auth/password/email",
            "POST",
            { email },
            false
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startRecoveryPassword = (password, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");
        const email = urlParams.get("email");
        const resp = await Call(
            "teachers/auth/password/reset",
            "POST",
            { password, token, email },
            false
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success.msg,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startRenew = () => {
    return async (dispatch) => {
        if (!localStorage.getItem("session")) {
            localStorage.removeItem("session");
            return dispatch(checkingFinish());
        }
        dispatch(startUILoading());
        const resp = await Call("teachers/auth/renew");
        if (resp.success) {
            const { user, session } = resp.success;
            localStorage.setItem("session", session);
            dispatch(login(user, session));
        } else {
            localStorage.removeItem("session");
            dispatch(checkingFinish());
        }
        dispatch(stopUILoading());
    };
};

export const startLogoutTeacher = (navigate) => {
    return async (dispatch) => {
        await Call("/teachers/auth/logout", "POST");
        localStorage.removeItem("session");
        navigate(`/teachers/login`);
        dispatch(logout());
    };
};
