import Swal from "sweetalert2";
import { errorHandler } from "../../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    objectToFormData,
} from "../../../../helpers/fetch";
import { types } from "../../../types";
import { startUILoading, stopUILoading } from "../ui/ui";

export const startLogin = (email, password, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        email = email.toLowerCase();
        const resp = await Call(
            "auth/login",
            "POST",
            { email, password },
            false
        ); // Sin generate_token para web
        if (resp.success) {
            const { user, session } = resp.success;

            localStorage.setItem("session", session); // Guardar session en localStorage
            dispatch(login(user, session));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startLoginByID = (user) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const formData = new FormData();
        formData.append("user_id", user.id);
        const resp = await CallWithFormData(
            "auth/loginByUserID",
            "POST",
            formData,
        );
        if (resp.success) {
            const { user, session } = resp.success;
            localStorage.setItem("session", session);
            dispatch(login(user, session));
        }
        dispatch(stopUILoading());
    };
};

export const startRenew = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        if (!localStorage.getItem("session")) {
            return dispatch(checkingFinish());
        }

        const resp = await Call("auth/renew", "GET", null, true);
        if (resp.success) {
            const { user, session } = resp.success;
            localStorage.setItem("session", session); // Actualizar session
            dispatch(login(user, session));
        } else {
            localStorage.removeItem("session"); // Invalidar si falla
            dispatch(checkingFinish());
        }

        dispatch(stopUILoading());
    };
};

export const startLogout = () => {
    return async (dispatch, getState) => {
        const { session } = getState().auth;

        const endpoints = {
            teacher: "teachers/auth/logout",
            student: "students/auth/logout",
            operator: "auth/logout",
        };

        await Call(endpoints[session] || "auth/logout", "POST");
        localStorage.removeItem("session"); // Limpiar solo session
        dispatch(logout());
    };
};

export const startForgotMyPassword = (email, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        email = email.toLowerCase().trim();
        const resp = await Call(
            "auth/password/email",
            "POST",
            { email },
            false
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startRecoveryPassword = (password, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");
        const email = urlParams.get("email");
        const resp = await Call(
            "auth/password/reset",
            "POST",
            { password, token, email },
            false
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: resp.success.msg,
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const login = (user, session) => ({
    type: types.authLogin,
    payload: { user, session },
});

export const checkingFinish = () => ({
    type: types.authCheckingFinish,
});

export const logout = () => ({
    type: types.authLogout,
});
