export const Call = async (
    endpoint,
    method = "GET",
    body = null,
    signed = true
) => {
    let url = `/api/${endpoint}`;
    const headers = new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
    });

    // Solo agregar CSRF para métodos que lo requieren

    //         const csrfToken = document.querySelector(
    //             'meta[name="csrf-token"]'
    //         )?.content;
    //         if (csrfToken) headers.append("X-CSRF-TOKEN", csrfToken);
    //  ¿

    const options = {
        method,
        headers,
        credentials: "include", // Siempre enviar y aceptar cookies
        body: body ? JSON.stringify(body) : null,
    };

    if (method === "GET" && body !== null) {
        url = `${url}?${body}`;
        delete options.body;
    }
    try {
        const resp = await fetch(url, options);
        const result = await resp.json();
        result.status_code = resp.status;
        result.request_ok = resp.ok;

        return result;
    } catch (error) {
        return { status_code: 0, request_ok: false, error: "Network error" };
    }
};

export const CallWithFormData = async (
    endpoint,
    method = "POST",
    body = null,
    signed = true
) => {
    let url = `/api/${endpoint}`;
    const headers = new Headers({ Accept: "application/json" });

    const options = {
        method,
        headers,
        credentials: "include", // Siempre enviar y aceptar cookies
        body,
    };

    if (method === "GET" && body !== null) {
        url = `${url}?${body}`;
        delete options.body;
    }

    const resp = await fetch(url, options);
    const result = await resp.json();
    result.status_code = resp.status;
    result.request_ok = resp.ok;

    return result;
};

export const CallWithFormDataFile = async (
    endpoint,
    method = "GET",
    body = null,
    signed = true
) => {
    let url = `/api/${endpoint}`;
    const headers = new Headers({ Accept: "application/json" });

    const options = {
        method,
        headers,
        credentials: "include", // Siempre enviar y aceptar cookies
        body,
    };

    if (method === "GET" && body !== null) {
        url = `${url}?${body}`;
        delete options.body;
    }

    const resp = await fetch(url, options);

    if (resp.status !== 200) {
        const result = await resp.json();
        result.status_code = resp.status;
        result.request_ok = resp.ok;
        return result;
    } else {
        return await resp.blob();
    }
};


export const SendDocumentToPrint = async (uri) => {
try {
    const response = await fetch(uri, { mode: "cors" }); // Asegura que el servidor permita CORS
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);

    const printWindow = window.open(blobURL, "_blank");
    if (printWindow) {
        setTimeout(() => printWindow.print(), 1000); // Espera para asegurar la carga antes de imprimir
    }
} catch (error) {
    console.error("Error al obtener el PDF:", error);
}
}
// Resto del archivo sin cambios
export const cleanEmpty = (data) => {
    Object.entries(data).forEach(([key, value]) => {
        if (key !== "errors") {
            if (
                !value ||
                value.length === 0 ||
                (typeof value === "object" &&
                    Object.entries(value).length === 0 &&
                    !data[key].name)
            ) {
                if (typeof data[key] == "string") {
                    data[key] = "";
                } else {
                    delete data[key];
                }
            }
        }
    });

    return data;
};

export const objectToFormData = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (value || value === false || value === 0) {
            if (value instanceof File) {
                formData.append(key, value);
            } else if (typeof value === "object") {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        } else {
            //? Solo Descarta:  undefined, "", null
        }
    });
    return formData;
};

export const mergeFormData = (formData1, formData2) => {
    const mergedFormData = new FormData();

    for (const pair of formData1.entries()) {
        mergedFormData.append(pair[0], pair[1]);
    }

    for (const pair of formData2.entries()) {
        mergedFormData.append(pair[0], pair[1]);
    }

    return mergedFormData;
};

export const downloadBlob = async (blob, filename) => {
    let url = URL.createObjectURL(await blob);
    let link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const downloadFileFromLink = async (href, fileName) => {
    try {
        const response = await fetch(href, {
            method: "GET",
        });

        if (!response.ok) {
            throw new Error("Error al descargar archivo");
        }

        const contentType = response.headers.get("Content-Type");
        let fileExtension = "";
        switch (contentType) {
            case "application/pdf":
                fileExtension = ".pdf";
                break;
            case "image/jpeg":
                fileExtension = ".jpg";
                break;
            case "image/png":
                fileExtension = ".png";
                break;
            case "application/msword":
                fileExtension = ".doc";
                break;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                fileExtension = ".docx";
                break;
            case "application/vnd.ms-excel":
                fileExtension = ".xls";
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                fileExtension = ".xlsx";
                break;
            default:
                fileExtension = ".pdf";
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName + fileExtension;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading the file:", error);
    }
};
