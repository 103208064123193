import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    mergeFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    activeStudent: null,
};

export const Sinapsis = createSlice({
    name: "Sinapsis",
    initialState,
    reducers: {
        setActiveStudent: (state, action) => {
            state.activeStudent = action.payload;
        },
        clearActiveStudent: (state) => {
            state.activeStudent = initialState.activeStudent;
        },
        clearState: (state) => {
            state = initialState;
        },
    },
});

export const { setActiveStudent, clearActiveStudent, clearState } =
    Sinapsis.actions;

export default Sinapsis.reducer;

export const startSinapsisImportStudent = (
    student_number,
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `services/sinapsis/api/import-student`,
            "POST",
            { student_number }
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Estudiante importado",
                icon: "success",
            });
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startSinapsisExportStudent = (
    student_number,
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `services/sinapsis/api/export-student`,
            "POST",
            { student_number }
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Estudiante exportado",
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startSinapsisUpdateDebt = (
    student_number,
    has_debt,
    callback = () => {},
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `services/sinapsis/api/update-debt`,
            "POST",
            { student_number, has_debt }
        );

        if(resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Adeudo actualizado",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 2000,
            });
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};
