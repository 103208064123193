import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    openTeachers: false,
    openModule: false,
    openForm: false,
    list_modules: [],
    groupsBySubject: [],
    groupTeachersByGrades: [],
    list: [],
    active: null,
};

export const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.groupOpenModalModules:
            return {
                ...state,
                openModule: true,
            };
        case types.groupShowListModules:
            return {
                ...state,
                list_modules: action.payload.list_modules,
            };
        case types.groupCloseModalModules:
            return {
                ...state,
                openModule: false,
            };
        case types.groupOpenModal:
            return {
                ...state,
                openForm: true,
            };
        case types.groupCloseModal:
            return {
                ...state,
                openForm: false,
                active: null,
            };
        case types.groupOpenModalTeachers:
            return {
                ...state,
                openTeachers: true,
            };
        case types.groupCloseModalTeachers:
            return {
                ...state,
                openTeachers: false,
            };
        case types.groupShow:
            return {
                ...state,
                pagination: action.payload.data,
            };
        case types.groupTeacherSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.groupsBySubject:
            return {
                ...state,
                groupsBySubject: action.payload,
            };
        case types.groupTeachersByGrades:
            return {
                ...state,
                groupTeachersByGrades: action.payload,
            };

        default:
            return state;
    }
};
