import { useDispatch, useSelector } from "react-redux";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useEffect } from "react";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_Reports = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "CYCLE_STUDENTS_REPORT",
        "SCHOLARSHIPS_REPORT",
        "GRADE_UPLOAD_REPORT",
        "TEACHERS_BY_CYCLE_REPORT",
        "ORIGIN_SCHOOLS_REPORT",
        "ACADEMIC_STATUS_REPORT",
        "CLASS_SCHEDULES_REPORT",
        "INSCRIPTIONS_BY_CYCLE_REPORT",
        "VEHICLE_PLATES_REPORT",
        "GENERAL_AVERAGES_REPORT",
        "STUDENTS_ATTENDANCES_REPORT",
        "ATTENDANCES_REPORT",
        "CLASS_LOAD_REPORT",
        "INSCRIPTION_SUMMARY",
        "SUBJECT_HOURS_REPORT",
        "LANGUAGE_FOLLOW_UP",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_Reports"));
        }
    }, [canShowAccordion]);

    return (
        <div>
            {true && (
                <SidebarNavAccordion
                    title={"Reportes"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-solid fa-files fa-xl"
                >
                    {hasPermission("CYCLE_STUDENTS_REPORT") && (
                        <NavLinkApp
                            name="Alumnos por ciclos"
                            to="/app/cycle/students/report"
                            icon={
                                <IconApp iconClassName="fas fa-solid fa-screen-users c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("STUDENTS_ATTENDANCES_REPORT") && (
                        <NavLinkApp
                            name="Asistencias alumnos"
                            to="/app/students/attendance/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-person-walking c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("ATTENDANCES_REPORT") && (
                        <NavLinkApp
                            name="Asistencias docentes"
                            to="/app/teacher/attendance/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-clipboard-user c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("SCHOLARSHIPS_REPORT") && (
                        <NavLinkApp
                            name="Becas"
                            to="/app/school/scholarships/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-backpack c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("GRADE_UPLOAD_REPORT") && (
                        <NavLinkApp
                            name="Carga de calificaciones"
                            to="/app/school/grade/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-file-chart-pie c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("CLASS_LOAD_REPORT") && (
                        <NavLinkApp
                            name="Carga horaria"
                            to="/app/class-load/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-calendar-exclamation c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("SUBJECT_HOURS_REPORT") && (
                        <NavLinkApp
                            name="Horas por materia"
                            to="/app/subject-hours/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-calendar-clock c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("TEACHERS_BY_CYCLE_REPORT") && (
                        <NavLinkApp
                            name="Docentes por ciclo"
                            to="/app/teachers/by/cycle"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-person-chalkboard c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("ORIGIN_SCHOOLS_REPORT") && (
                        <NavLinkApp
                            name="Escuelas de procedencia"
                            to="/app/students/report/origin-schools"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-school c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("ACADEMIC_STATUS_REPORT") && (
                        <NavLinkApp
                            name="Estatus académico"
                            to="/app/students/academic/status"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-wave-pulse c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("CLASS_SCHEDULES_REPORT") && (
                        <NavLinkApp
                            name="Horarios por edificio"
                            to="/app/class-schedules/report"
                            icon={
                                <IconApp iconClassName="fas fa-building c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("INSCRIPTIONS_BY_CYCLE_REPORT") && (
                        <NavLinkApp
                            name="Inscripciones por ciclo"
                            to="/app/students/report/inscriptions-cycle"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-arrows-spin c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("VEHICLE_PLATES_REPORT") && (
                        <NavLinkApp
                            name="Placas vehiculares"
                            to="/app/vehicle-plates/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-cars c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("GENERAL_AVERAGES_REPORT") && (
                        <NavLinkApp
                            name="Promedios generales"
                            to="/app/school/grade/report/averages/general"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-ranking-star c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["INSCRIPTION_SUMMARY"]) && (
                        <NavLinkApp
                            name="Resumen de inscripción"
                            to="/app/inscriptionsummary"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-file-chart-pie c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission(["LANGUAGE_FOLLOW_UP"]) && (
                        <NavLinkApp
                            name="Seguimiento de idiomas"
                            to="/app/language/follow-up"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-earth-americas c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
