import { types } from "../../../types";

const initialState = {
    checking: true,
    session: null,
    user: null,
    location: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        // Users
        case types.authRegister:
        case types.authLogin:
        case types.teacherAuthLogin:
        case types.studentAuthLogin:
            return {
                ...state,
                checking: false,
                session: action.payload.session,
                user: action.payload.user,
            };
        case types.authLogout:
        case types.teacherAuthLogout:
        case types.studentAuthLogout:
            localStorage.removeItem("session"); // Solo limpiamos session, no token
            return {
                checking: false,
                session: null,
                user: null,
                location: null,
            };
        case types.authCheckingFinish:
        case types.teacherAuthCheckingFinish:
        case types.studentAuthCheckingFinish:
            localStorage.removeItem("session"); // Limpiamos session si falla el chequeo
            return {
                ...state,
                checking: false,
                user: null,
                session: null,
                location: null,
            };
        case types.newPractice:
            return {
                ...state,
                user: {
                    ...state.user,
                    profesional_practices: [action.payload],
                },
            };
        case types.newSocialService:
            return {
                ...state,
                user: {
                    ...state.user,
                    social_service: [action.payload],
                },
            };
        default:
            return state;
    }
};
