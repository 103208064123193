import { lazy } from "react";

const ProfileStudentScreen = lazy(() =>
    import("../../views/students/profile/ProfileScreen")
);

const FormPayUTest = lazy(() => import("../../views/students/FormPayUTest"));

const StudentsBillingStatus = lazy(() =>
    import("../../views/students/Billing/BillingStatus")
);

const StudentsBillingShoppingCart = lazy(() =>
    import("../../views/students/billing/ShoppingCart")
);

const StudentsScheduleScreen = lazy(() =>
    import("../../views/students/schedule/ScheduleScreen")
);

const StudentsAttendanceScreen = lazy(() =>
    import("../../views/students/Attendances/AttendanceScreen")
);

const StudentsScholarshipScreen = lazy(() =>
    import("../../views/students/Scholarship_request/ScholarshipRequestScreen")
);

const StudentsEducationalCreditsScreen = lazy(() =>
    import("../../views/students/educational_credits/EducationalCreditsScreen")
);

const StudentExtraPaymentsScreen = lazy(() =>
    import("../../views/students/Extra_payments/StudentExtraPaymentScreen")
);

const StudentPaymentsHistoryScreen = lazy(() =>
    import("../../views/students/Payments/StudentPaymentsHistoryScreen")
);
const StudentLoadDocumentsScreen = lazy(() =>
    import("../../views/students/loadDocuments/StudentLoadDocumentsScreen")
);

const StudentGradesScreen = lazy(() =>
    import("../../views/students/StudentSession/Grades/GradesScreen")
);

const StudentSubjectsScreen = lazy(() =>
    import("../../views/students/studentSession/Subjects/SubjectsScreen")
);

const StudentReportPayments = lazy(() =>
    import("../../views/students/report/StudentsReport")
);

const StudentClarificationScreen = lazy(() =>
    import("../../views/students/Aclarations/StudentClarificationScreen")
);
const StudentOrphanhoodPDF = lazy(() =>
    import("../../views/students/report/StudentOrphanhoodPDF")
);
const StudentInscriptionDocument = lazy(() =>
    import("../../views/students/report/StudentInscriptionReport")
);
const InternshipSocialServiceScreen = lazy(() =>
    import(
        "../../views/students/internships_socialservice/InternshipSocialServiceScreen"
    )
);
const SurveyScreen = lazy(() =>
    import("../../views/students/Surveys/SurveyScreen")
);
const SurveyAssignment = lazy(() =>
    import("../../views/students/Surveys/SurveyAssignment")
);
const GenerateReferencesScreen = lazy(() =>
    import("../../views/students/Payments/GenerateReferencesScreen")
);

export const StudentRoutes = {
    ProfileStudentScreen,
    FormPayUTest,
    StudentsBillingStatus,
    StudentsBillingShoppingCart,
    StudentsScheduleScreen,
    StudentsScholarshipScreen,
    StudentsEducationalCreditsScreen,
    StudentExtraPaymentsScreen,
    StudentPaymentsHistoryScreen,
    StudentGradesScreen,
    StudentSubjectsScreen,
    StudentReportPayments,
    StudentClarificationScreen,
    StudentLoadDocumentsScreen,
    StudentOrphanhoodPDF,
    StudentInscriptionDocument,
    InternshipSocialServiceScreen,
    GenerateReferencesScreen,
    StudentsAttendanceScreen,
    SurveyScreen,
    SurveyAssignment,
};
