import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    report: null,
};

export const DebtsByMonth = createSlice({
    name: "DebtsByMonth",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        clearReport: (state) => {
            state.report = initialState.report;
        },
    },
});

export const { setReport, clearReport } = DebtsByMonth.actions;

export default DebtsByMonth.reducer;

// Actions
export const startShow = ({
    cycles,
    months,
    concept_id,
    status,
    curricula,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = cycles
            .map((cycle) => `${cycle.year}-${cycle.sub_cycle}`)
            ?.join(",");
        const join_months = months?.map((m) => m?.value)?.join(",");
        const join_concept_id = concept_id?.map((cid) => cid?.value)?.join(",");
        const join_status = status?.map((s) => s?.value)?.join(",");
        const join_curricula = curricula?.map((c) => c?.value)?.join(",");

        const params = new URLSearchParams();

        params.set("cycles", cycles_data);
        params.set("months", join_months);

        if (join_concept_id) {
            params.set("concept_id", join_concept_id);
        }
        if (join_status) {
            params.set("status", join_status);
        }
        if (join_curricula) {
            params.set("curricula", join_curricula);
        }

        const resp = await Call(
            `operators/reports/academic-records/debts`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setReport(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startExportReport = ({
    cycles,
    months,
    concept_id,
    status,
    curricula,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = cycles
            .map((cycle) => `${cycle.year}-${cycle.sub_cycle}`)
            ?.join(",");
        const join_months = months?.map((m) => m?.value)?.join(",");
        const join_concept_id = concept_id?.map((cid) => cid?.value)?.join(",");
        const join_status = status?.map((s) => s?.value)?.join(",");
        const join_curricula = curricula?.map((c) => c?.value)?.join(",");

        const params = new URLSearchParams();

        params.set("export", 1);
        params.set("cycles", cycles_data);
        params.set("months", join_months);

        if (join_concept_id) {
            params.set("concept_id", join_concept_id);
        }
        if (join_status) {
            params.set("status", join_status);
        }
        if (join_curricula) {
            params.set("curricula", join_curricula);
        }

        const response = await CallWithFormDataFile(
            `operators/reports/academic-records/debts`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de adeudos");

        dispatch(stopUILoading());
    };
};

export const startExportGeneralDebts = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("export", 1);

        const response = await CallWithFormDataFile(
            `operators/reports/academic-records/debts/general`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de adeudos generales");

        dispatch(stopUILoading());
    };
};

export const startExportStudentPaymentHistory = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("export", 1);

        const response = await CallWithFormDataFile(
            `operators/reports/academic-records/payments/history`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Historial de pagos");

        dispatch(stopUILoading());
    };
};

export const startExportStudentPaymentHistoryByCyle = (values, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const {cycles} = values;
        const cycleText = `${cycles.year}-${cycles.sub_cycle}`

        const params = new URLSearchParams();
        params.set("checkInscriptionCycle", cycleText);

        const response = await CallWithFormDataFile(
            `operators/reports/academic-records/payments/history/by/cycle`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Historial de pagos por ciclo");
        callback();
        dispatch(stopUILoading());
    };
};
