import { useEffect, useState } from "react";

const useVersionCheck = () => {
    const [message, setMessage] = useState("");

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await fetch("/version.json", {
                    cache: "no-store", // Evita el cache para siempre obtener la versión más reciente
                });
                if (!response.ok)
                    throw new Error("No se pudo obtener la versión");

                const data = await response.json();
                const newVersion = data.version;

                // Obtener la versión guardada en localStorage
                const savedVersion = localStorage.getItem("appVersion");

                // Si la versión guardada no coincide con la nueva, mostrar mensaje y recargar
                if (savedVersion && savedVersion !== newVersion) {
                    setMessage("✅ Nueva versión detectada. Recargando...");

                    // Actualiza la versión en localStorage
                    localStorage.setItem("appVersion", newVersion);

                    setTimeout(() => {
                        window.location.reload();
                    }, 3000); // Espera 3 segundos antes de recargar
                } else {
                    // Si las versiones coinciden, actualiza localStorage (por si hay alguna discrepancia)
                    localStorage.setItem("appVersion", newVersion);
                }
            } catch (error) {
                setMessage("❌ Error al verificar la versión.");
            }
        };

        // Ejecutar la verificación inmediatamente al montar el componente
        checkVersion();
    }, []); // Solo se ejecuta una vez al montar el componente

    return message;
};

export default useVersionCheck;
