import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormData,
    mergeFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    pagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    addPagination: {
        data: [],
        per_page: 10,
        total: 0,
    },
    active: null,
    list: [],
    assignedList: [],
};

export const Warehouse = createSlice({
    name: "Warehouse",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },

        setAddPagination: (state, action) => {
            state.addPagination = action.payload;
        },
        clearAddPagination: (state) => {
            state.addPagination = initialState.addPagination;
        },

        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.list = initialState.list;
        },

        setAssignedList: (state, action) => {
            state.assignedList = action.payload;
        },
        clearAssignedList: (state) => {
            state.assignedList = initialState.assignedList;
        },

        setActive: (state, action) => {
            state.active = action.payload;
        },
        clearActive: (state) => {
            state.active = initialState.active;
        },

        clearState: (state) => {
            state = initialState;
        },
    },
});

export const {
    setPagination,
    clearPagination,

    setAddPagination,
    clearAddPagination,

    setList,
    clearList,

    setAssignedList,
    clearAssignedList,

    setActive,
    clearActive,

    clearState,
} = Warehouse.actions;

export default Warehouse.reducer;

export const startSaveWarehouseItem = (
    values,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/warehouse/item/create`,
            "POST",
            values
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro de artículo de inventario creado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startShowWarehouseItems = (
    values,
    targetPagitation = "pagination"
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const {
            page = null,
            number_rows = null,
            query = null,
            category = null,
            status = null,
            except = null,
        } = values;

        const params = new URLSearchParams();
        params.set("page", page);
        params.set("per_page", number_rows);
        params.set("q", query);
        if (category) {
            params.set("category", category);
        }
        if (status) {
            params.set("status", status);
        }
        if (except) {
            params.set("except", except);
        }

        const resp = await Call(
            `operators/warehouse/item`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            switch (targetPagitation) {
                case "pagination":
                    dispatch(setPagination(resp.success));
                    break;
                case "addPagination":
                    dispatch(setAddPagination(resp.success));
                    break;
            }
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateWarehouseItem = (
    values,
    setErrors,
    callback = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/warehouse/item/update/${values.id}`,
            "PATCH",
            values
        );

        if (resp.success) {
            callback();
            Swal.fire({
                title: "Éxito",
                text: "Registro de artículo de inventario actualizado.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startGetUserAssignedWarehouseItems = (user_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/warehouse/item/user/assigned/${user_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setAssignedList(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startAssignUserItems = (values, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/warehouse/item/user/assign/${values.user_id}`,
            "POST",
            values
        );

        if (resp.success) {
            callback();
            dispatch(setAssignedList(resp.success));
            Swal.fire({
                title: "Éxito",
                text: "Registro artículos asignados a usuario actualizados.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteWarehouseItem = (id, callback = () => {}) => {
    return async (dispatch) => {
        deleteConfirmModal(async () => {
            dispatch(startUILoading());

            const resp = await Call(
                `operators/warehouse/item/delete/${id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Registro de artículo de inventario eliminado.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp);
            }

            dispatch(stopUILoading());
        });
    };
};
