import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useHasPermission = () => {
    const { user, session } = useSelector((state) => state.auth);
    const { tiny_name: campus } = useSelector((state) => state.config);
    const isLogged = !!user;


    const hasPermission = useMemo(() => {
        return (permissions = []) => {
            if (!isLogged) {
                console.error("Hook useHasPermission:", "Debes iniciar sesión.");
                return false;
            }

            const { modules } = user;

            let permissionsArray = [];

            if (typeof permissions === "string") {
                permissionsArray.push(permissions);
            } else {
                if (typeof permissions === "object") {
                    permissionsArray = permissions;
                } else {
                    console.error(
                        "Hook useHasPermission:",
                        "Debes pasar un ´array´ o un ´string´"
                    );
                    return false;
                }
            }

            const modules_code = modules.map((m) => m.code);

            for (let i = 0; i < modules_code.length; i++) {
                for (let j = 0; j < permissionsArray.length; j++) {
                    if (modules_code[i] === permissionsArray[j]) {
                        return true;
                    }
                }
            }
            return false;
        };
    }, [isLogged, user]);

    return { hasPermission, isLogged, session, campus };
};
