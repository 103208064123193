import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../../actions/administrador/ui/ui";
import { Call } from "../../../../helpers/fetch";
import { errorHandler } from "../../../../helpers/errorHandler";
import { deleteConfirmModal } from "../../../../helpers/DeleteValidate";
import Swal from "sweetalert2";

const initialState = {
    list: [],
    list_group: [],
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    openForm: false,
};

export const roles = createSlice({
    name: "roles",
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        clearPagination: (state) => {
            state.pagination = initialState.pagination;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        clearList: (state) => {
            state.pagination = initialState.list;
        },
        toggleForm: (state) => {
            state.openForm = !state.openForm;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        clearActive: (state) => {
            state.active = initialState.active;
        },
    },
});

export const {
    toggleForm,
    setActive,
    clearActive,
    setPagination,
    clearPagination,
    setList,
    clearList,
} = roles.actions;

export default roles.reducer;

// ACTIONS

export const startShowRoles = (values) => {
    return async (dispatch) => {
        const asList = !!values.asList;

        if (!asList) dispatch(startUILoading());

        const params = new URLSearchParams();

        if (values.query) {
            params.set("q", values.query);
        }
        if (values.page) {
            params.set("page", values.page);
        }
        if (values.number_rows) {
            params.set("per_page", values.number_rows);
        }
        params.set("list", asList ? 1 : 0);

        const resp = await Call(`users/roles`, "GET", params.toString());

        if (resp.success) {
            if (asList) {
                dispatch(setList(resp.success.roles));
            } else {
                const pagination = resp.success.roles;
                dispatch(setPagination(pagination));
            }
        }

        if (!asList) dispatch(stopUILoading());
    };
};

export const startSaveRole = (data, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call("users/roles", "POST", data);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "El rol se ha creado y se ha aplicado a los usuarios correctamente.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(setActive(resp.success.data));
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateRole = (data, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`users/roles/${data.role_id}`, "PUT", data);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "El rol se ha actualizado y se ha aplicado a los usuarios correctamente.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(setActive(resp.success.data));
            callback();
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startDeleteRole = (role_id, setErrors, callback = () => {}) => {
    return async (dispatch) => {
        await deleteConfirmModal(
            async () => {
                dispatch(startUILoading());
                const resp = await Call(`users/roles/${role_id}}`, "DELETE");

                if (resp.success) {
                    callback();

                    Swal.fire({
                        title: "Exito",
                        text: "Se ha eliminado el rol correctamente",
                        icon: "success",
                        toast: true,
                        position: "bottom-end",
                        timer: 8000,
                    });
                } else {
                    errorHandler(resp, setErrors);
                }
                dispatch(stopUILoading());
            },
            false,
            false
        );
    };
};
