import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormData,
    CallWithFormDataFile,
    downloadBlob,
    objectToFormData,
} from "../../../helpers/fetch";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { deleteConfirmModal } from "../../../helpers/DeleteValidate";

const initialState = {
    scholarshipsByCycle: [],
    scholarshipsStock: [],
};

export const PartnershipScholarship = createSlice({
    name: "PartnershipScholarship",
    initialState,
    reducers: {
        setScholarShips: (state, action) => {
            state.scholarshipsByCycle = action.payload;
        },
        setScholarshipStock: (state, action) => {
            state.scholarshipsStock = action.payload;
        },
        clearScholarshipStock: (state, action) => {
            state.scholarshipsStock = initialState.scholarshipsStock;
        },
        cleanForm: (state, action) => {
            state = initialState;
        },
    },
});

export const {
    setScholarShips,
    setScholarshipStock,
    clearScholarshipStock,
    cleanForm,
} = PartnershipScholarship.actions;

export default PartnershipScholarship.reducer;

export const startShow = ({ partnership, cycle }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();
        params.set("year", cycle.year);
        params.set("sub_cycle", cycle.sub_cycle);

        const resp = await Call(
            `operators/partnerships/scholarships/${partnership.id}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setScholarShips(resp.success.scholarships));
        }

        dispatch(stopUILoading());
    };
};

export const startGetPartnershipScholarshipStock = ({
    partnership_id,
    cycle_id,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/partnerships/scholarships/cycle-stock/${partnership_id}/${cycle_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setScholarshipStock(resp.success));
        }

        dispatch(stopUILoading());
    };
};

export const startGenerateParnershipsScholarshipsReportByCycle = (cycles) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const {year, sub_cycle} = cycles;

        const params = new URLSearchParams();
        params.set("year", year);
        params.set("sub_cycle", sub_cycle);

        const response = await CallWithFormDataFile(
            "operators/partnerships/scholarships/report-by-cycle",
            "GET",
            params.toString(),
        );

        downloadBlob(response, "Reporte de becas de convenios");

        dispatch(stopUILoading());
    };
};

export const startSave = ({ partnership, scholarships, cycle }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const data = {
            partnership_id: partnership.id,
            year: cycle.year,
            sub_cycle: cycle.sub_cycle,
            scholarships: Object.entries(scholarships).map(([key, qty]) => ({
                percentage: key.replace("scholarship_", ""),
                qty: qty || 0,
            })),
        };
        const resp = await CallWithFormData(
            `operators/partnerships/scholarships/register`,
            "POST",
            objectToFormData(data)
        );

        if (resp.success) {
            console.log(resp);
            Swal.fire("Éxito", "Becas guardadas correctamente", "success");
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};
